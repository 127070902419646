<template>
  <div style="margin-top:84px;color:black;" class="pl-4 pb-10 pt-10">
    <p class="text-h5">
      <strong>KOOOLA Privacy Policy</strong>
    </p>
    <p>Last Revised: November 11, 2021</p>
    <p>
      IMAGIC PTE. LTD. (&ldquo;KOOOLA,&rdquo; &ldquo;we&rdquo; or
      &ldquo;us&rdquo;) is committed to protecting your privacy. We have
      prepared this Privacy Policy to describe to you our practices regarding
      the Personal Information (as defined below) we collect, why we collect it,
      and how we use and disclose it.
    </p>
    <p>
      Your privacy matters to us, so please do take the time to get to know and
      familiarize yourself with our policies and practices. Please understand
      that we reserve the right to change any of our policies and practices at
      any time, but you can always find the latest version of this Privacy
      Policy here on this page.
    </p>
    <p class="text-h6">
      <strong>Personal Information We Collect</strong>
    </p>
    <p>
      As used herein, &ldquo;Personal Information&rdquo; means information that
      identifies or is reasonably capable of identifying an individual, directly
      or indirectly, and information that is capable of being associated with an
      identified or reasonably identifiable individual.
    </p>
    <p>
      <strong> 1. Personal Information we collect from you</strong>
    </p>
    <p>
      We may collect the following categories of Personal Information directly
      from you:
    </p>
    <p>
      <ul>
        <li>
          <strong>Identification Information</strong> such as name, email, phone
          number, postal address;
        </li>
        <li>
          <strong>Commercial Activity</strong> such as trading activity, order
          activity, deposits, withdrawals, account balances;
        </li>
        <li>
          <strong>Correspondence</strong> such as information you provide to us in
          correspondence, including account opening and customer support; and
        </li>
        <li>
          <strong>Sensory Information</strong> such as images that you upload to
          your User Profile;
        </li>
      </ul>
    </p>
    <p>
      <strong>2. Personal Information we collect automatically</strong>
    </p>
    <p>
      We may collect the following categories of Personal Information
      automatically through your use of our services:
    </p>
    <p>
      <ul>
        <li>
          <strong>Online Identifiers</strong> such as IP address, domain name;
        </li>
        <li>
          <strong>Device Information</strong> such as hardware, operating system,
          browser;
        </li>
        <li>
          <strong>Usage Data</strong> such as system activity, internal and
          external information related to KOOOLA pages that you visit, clickstream
          information; and
        </li>
      </ul>
    </p>
    <p>
      Our automatic collection of Personal Information may involve the use of
      Cookies, described in greater detail below.
    </p>
    <p>
      <strong>3. Personal Information we collect from third parties</strong>
    </p>
    <p>
      We may collect and/or verify the following categories of Personal
      Information about you from third parties, including service providers and
      our affiliates:
    </p>
    <p>
      <ul>
        <li>
          <strong>Identification Information</strong> such as name, email, phone
          number, postal address
        </li>
        <li>
          <strong>Transaction Information</strong> such as public blockchain data
          (Ether, Solana, USD Coin, and other Digital Assets are not truly
          anonymous. We, and any others who can match your public Digital Asset
          address to other Personal Information about you, may be able to identify
          you from a blockchain transaction because, in some circumstances,
          Personal Information published on a blockchain (such as your Digital
          Asset address and IP address) can be correlated with Personal
          Information that we and others may have. Furthermore, by using data
          analysis techniques on a given blockchain, it may be possible to
          identify other Personal Information about you);
        </li>
        <li>
          <strong>Financial Information</strong> such as bank account information,
          routing number, credit card number, debit card number; and
        </li>
        <li>
          <strong>Additional Information</strong> at our discretion to comply with
          legal obligations.
        </li>
      </ul>
    </p>
    <p>
      <strong>4. Accuracy and retention of Personal Information</strong>
    </p>
    <p>
      We take reasonable and practicable steps to ensure that your Personal
      Information held by us is (i) accurate with regard to the purposes for
      which it is to be used, and (ii) not kept longer than is necessary for the
      fulfillment of the purpose for which it is to be used.
    </p>
    <p class="text-h6">
      <strong>How We Use Your Personal Information</strong>
    </p>
    <p>
      We collect Personal Information about you in an attempt to provide you
      with the best experience possible, protect you from risks related to
      improper use and fraud, and help us maintain and improve our Services. We
      may use your Personal Information to:
    </p>
    <p>
      <ul>
        <li>
          <strong>Provide you with our Services. </strong>We use your Personal
          Information to provide you with our Services pursuant to the terms of
          our Terms of Use. For example, in order to facilitate fiat transfers out
          of your account, we need to know your financial information.
        </li>
        <li>
          <strong>Comply with legal and regulatory requirements.</strong> We
          process your Personal Information as required by applicable laws and
          regulations.
        </li>
        <li>
          <strong>Detect and prevent fraud.</strong> We process your Personal
          Information to detect and prevent fraud on your account, which is
          especially important given the irreversible nature of cryptocurrency
          transactions.
        </li>
        <li>
          <strong>Protect the security and integrity of our Services.</strong> We
          use your Personal Information, including information about your device
          and your activity on Realy to maintain the security of your account and
          the Realy platform.
        </li>
        <li>
          <strong>Provide you with customer support.</strong> We process your
          Personal Information when you contact our support team with questions
          about or issues with your account.
        </li>
        <li>
          <strong>Market our products.</strong> We may contact you with
          information about our Services. We will only do so with your permission,
          which can be revoked at any time.
        </li>
        <li>
          <strong>Other business purposes.</strong> We may use your Personal
          Information for additional purposes if that purpose is disclosed to you
          before we collect the information or if we obtain your consent.
        </li>
      </ul>
    </p>
    <p class="text-h6">
      <strong>How We Share Your Personal Information</strong>
    </p>
    <p>
      We will not share your Personal Information with third parties, except as
      described below:
    </p>
    <p>
      <ul>
        <li>
          <strong>Service Providers.</strong> We may share your Personal
          Information with third-party service providers for business or
          commercial purposes, including fraud detection and prevention, security
          threat detection, payment processing, customer support, data analytics,
          Information Technology, advertising and marketing, network
          infrastructure, storage, transaction monitoring. We share your Personal
          Information with these service providers only so that they can provide
          us with the services, and we prohibit our service providers from using
          or disclosing your Personal Information for any other purpose.
        </li>
        <li>
          <strong>KOOOLA Creators.</strong> In some circumstances, KOOOLA may
          share your contact information with KOOOLA Creators to facilitate the
          delivery of Additional Items under the Terms of Use.
        </li>
        <li>
          We may share Personal Information about you with our affiliates in the
          ordinary course of business and offering our Services to you.
        </li>
        <li>
          <strong>Law Enforcement.</strong> We may be compelled to share your
          Personal Information with law enforcement, government officials, and
          regulators.
        </li>
        <li>
          <strong>Corporate Transactions.</strong> We may disclose Personal
          Information in the event of a proposed or consummated merger,
          acquisition, reorganization, asset sale, or similar corporate
          transaction, or in the event of a bankruptcy or dissolution.
        </li>
        <li>
          <strong>Professional Advisors.</strong> We may share your Personal
          Information with our professional advisors, including legal, accounting,
          or other consulting services for purposes of audits or to comply with
          our legal obligations.
        </li>
        <li>We may share your Personal Information with your consent.</li>
      </ul>
    </p>
    <p>
      If we decide to modify the purpose for which your Personal Information is
      collected and used, we will amend this Privacy Policy.
    </p>
    <p class="text-h6">
      <strong>Cookies</strong>
    </p>
    <p>
      When you access KOOOLA, we may make use of the standard practice of
      placing tiny data files called cookies, flash cookies, pixel tags, or
      other tracking tools (herein, &ldquo;Cookies&rdquo;) on your computer or
      other devices used to visit KOOOLA. We use Cookies to help us recognize
      you as a customer, collect information about your use of KOOOLA to better
      customize our services and content for you, and collect information about
      your computer or other access devices to: (i) ensure that your account
      security has not been compromised by detecting irregular, suspicious, or
      potentially fraudulent account activities; (ii) assess and improve our
      services and advertising campaigns.
    </p>
    <p>
      You also can learn more about cookies by visiting
      <u>http://www.allaboutcookies.org</u>, which includes additional useful
      information on cookies and how to block cookies on different types of
      browsers and mobile devices. Please note that if you reject cookies, you
      will not be able to use some or all of KOOOLA. If you do not consent to
      the placing of Cookies on your device, please do not visit, access, or use
      KOOOLA.
    </p>
    <p class="text-h6">
      <strong>Direct Marketing</strong>
    </p>
    <p>
      Subject to applicable laws and regulations, we may from time to time send
      direct marketing materials promoting services, products, facilities, or
      activities to you using information collected from or about you. You may
      also opt-out of such communications by following the directions provided
      in any marketing communication. It is our policy to not provide your
      Personal Information for those third parties&rsquo; direct marketing
      purposes without your consent.
    </p>
    <p class="text-h6">
      <strong>Information Security</strong>
    </p>
    <p>
      No security is foolproof, and the Internet is an insecure medium. We
      cannot guarantee absolute security, but we work hard to protect KOOOLA and
      you from unauthorized access to or unauthorized alteration, disclosure, or
      destruction of Personal Information we collect and store. Measures we take
      include encryption of the KOOOLA website communications with SSL; optional
      two-factor authentication; periodic review of our Personal Information
      collection, storage, and processing practices; and restricted access to
      your Personal Information on a need-to-know basis for our employees,
      contractors and agents who are subject to strict contractual
      confidentiality obligations and may be disciplined or terminated if they
      fail to meet these obligations.
    </p>
    <p class="text-h6">
      <strong>Information For Persons Subject to EU Data Protection Law</strong>
    </p>
    <p>
      While customers who are located in the European Union (&ldquo;EU&rdquo;),
      European Economic Area (&ldquo;EEA&rdquo;) or the Channel Islands, or
      other locations subject to EU data protection law (collectively,
      &ldquo;Europe&rdquo;) are customers of our Singapore entity, we recognize
      and, to the extent applicable to us, adhere to relevant EU data protection
      laws. For purposes of this section, &ldquo;personal data&rdquo; has the
      meaning provided in the General Data Protection Regulation (EU) 2016/679
      (&ldquo;GDPR&rdquo;).
    </p>
    <p class="text-h6">
      <strong>Lawful bases for processing</strong>
    </p>
    <p>
      We process personal data subject to GDPR on one or more of the following
      legal bases:
    </p>
    <p>
      <ul>
        <li>
          <strong>Legal Obligation</strong>: to conduct anti-fraud and to fulfill
          our retention and other legal obligations;
        </li>
        <li>
          <strong>Contractual Obligation</strong>: to satisfy our obligations to
          you under our Terms of Use, including to provide you with our Services
          and customer support services, and to optimize and enhance Realy;
        </li>
        <li>
          <strong>Legitimate Interest</strong>: to monitor the usage of KOOOLA,
          conduct automated and manual security checks of our Services, to protect
          our rights; and
        </li>
        <li>
          <strong>Consent</strong>: to market KOOOLA and our Services. You may
          withdraw your consent at any time without affecting the lawfulness of
          processing based on consent before consent is withdrawn.
        </li>
      </ul>
    </p>
    <p class="text-h6">
      <strong>European privacy rights</strong>
    </p>
    <p>
      European residents have the following rights under GDPR, subject to
      certain exceptions provided under the law, with respect to their personal
      data:
    </p>
    <p>
      <ul>
        <li>
          <strong>Rights to Access and Rectification</strong>. You may submit a
          request that KOOOLA disclose the personal data that we process about you
          and correct any inaccurate personal data.
        </li>
        <li>
          <strong>Right to Erasure</strong>. You may submit a request that KOOOLA
          delete the personal data that we have about you.
        </li>
        <li>
          <strong>Right to Restriction of Processing</strong>. You have the right
          to restrict or object to our processing of your personal data under
          certain circumstances.
        </li>
        <li>
          <strong>Right to Data Portability</strong>. You have the right to
          receive the personal data you have provided to us in an electronic
          format and to transmit that personal data to another data controller.
        </li>
      </ul>
    </p>
    <p>
      To submit a request to exercise these rights, please contact us using the
      methods described at the end of this Privacy Policy. When handling
      requests to exercise European privacy rights, we check the identity of the
      requesting party to ensure that he or she is the person legally entitled
      to make such request. While we maintain a policy to respond to these
      requests free of charge, should your request be repetitive or unduly
      onerous, we reserve the right to charge you a reasonable fee for
      compliance with your request.
    </p>
    <p class="text-h6">
      <strong>Collection and transfer of data outside the EEA</strong>
    </p>
    <p>
      KOOOLA operates with many of our systems based in Singapore. As a result,
      we may transfer personal data from Europe to third countries outside of
      Europe, including Singapore, under the following conditions:
    </p>
    <p>
      <ul>
        <li>
          <strong>Contractual Obligation</strong>. Where transfers are necessary
          to satisfy our obligation to you under our Terms of Use, including to
          provide you with our Services and customer support services, and to
          optimize and enhance Realy; and
        </li>
        <li>
          <strong>Consent</strong>: where you have consented to the transfer of
          your personal data to a third country.
        </li>
      </ul>
    </p>
    <p>
      Where transfers to a third country are based on your consent, you may
      withdraw your consent at any time. Please understand, however, that our
      services may not be available if we are unable to transfer personal data
      to third countries.
    </p>
    <p>
      When we transfer personal data to third countries, we endeavor to ensure
      adequate safeguards are implemented, for example through the use of
      standard contractual clauses or Privacy Shield certification.
    </p>
    <p class="text-h6">
      <strong>Automated decision-making</strong>
    </p>
    <p>
      We may engage in automated decision-making for purposes of fraud detection
      and prevention.
    </p>
    <p class="text-h6">
      <strong>Contact Us</strong>
    </p>
    <p>
      If you have questions or concerns regarding this policy or our processing
      of your Personal Information, please feel free to email us at
      hello@kooola.com.
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['language'])
  }
}
</script>

<style></style>
